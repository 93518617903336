@tailwind base;
@tailwind components;
@tailwind utilities;
@import "material-icons/iconfont/material-icons.css";
@import "bootstrap/dist/css/bootstrap.css";

.btn-auto {
  min-width: 8rem;
}

.bg-light-blue {
  background: rgb(240 249 255);
}

@media (max-width: 350px) {
  .btn-auto {
    /* line-height: 1.75; */

    min-width: 6.5rem;
  }
}

@media (max-width: 300px) {
  .btn-auto {
    font-size: 0.75rem;
    line-height: 1.75;

    min-width: 5rem;
  }
}

.bt-blue {
  background-color: rgb(59 130 246);
  border-color: transparent;
}

.bt-blue:hover {
  filter: brightness(95%);
  border-color: transparent;
}

.bt-gray {
  background-color: rgb(148 163 184);
  border-color: transparent;
  /* color: rgb(56, 62, 73); */
}

.bt-gray:hover {
  filter: brightness(95%);
  border-color: transparent;
}

.navbar-bg {
  background-color: rgb(149, 162, 173);
}

.in-hover-to-pointer:hover {
  cursor: pointer;
}

.text-gray {
  color: rgb(144, 146, 147);
}

.delirium-result-form {
  display: flex;
  width: 400px;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 4px;
  background: #fff;

  box-shadow: 0px 7px 16px 0px rgba(24, 30, 111, 0.15);
}

.delirium-result-form .top {
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: center;
  gap: 11px;
  align-self: stretch;
}

/* 기본 스타일 */
.partner-organization-logo {
  width: 200px;
}

@media (max-width: 675px) {
  .responsive-column {
    /* display: flex; */
    flex-direction: column;
  }
}

@media (max-width: 550px) {
  .text-hide-on-550 {
    display: none;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
